import React, { PureComponent } from 'react'
import styled from 'styled-components'

import MenuItem from './menuItem'
import { ListItem } from '../temporaryDrawer'

const DropdownWrapper = styled.div`
    color: white;
    margin-right: 40px;
    display: flex;
    position: relative;
    cursor: pointer;
    font-family: roboto;
`

interface DropdownProps {
    items: ListItem[]
}

export default class Dropdown extends PureComponent<DropdownProps, {}> {
    render() {
        return (
            <DropdownWrapper>
                {
                    this.props.items.map(item => 
                        <MenuItem 
                            key={item.key ? item.key : item.id} 
                            id={item.id}
                            name={item.name}
                            href={item.href}
                            nestedItems={item.nestedItems}/>
                    )
                }
            </DropdownWrapper>
        )
    }
}